exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-vsl-1-js": () => import("./../../../src/pages/services/vsl-1.js" /* webpackChunkName: "component---src-pages-services-vsl-1-js" */),
  "component---src-pages-services-vsl-2-js": () => import("./../../../src/pages/services/vsl-2.js" /* webpackChunkName: "component---src-pages-services-vsl-2-js" */),
  "component---src-pages-services-vsl-3-js": () => import("./../../../src/pages/services/vsl-3.js" /* webpackChunkName: "component---src-pages-services-vsl-3-js" */),
  "component---src-pages-services-vsl-4-js": () => import("./../../../src/pages/services/vsl-4.js" /* webpackChunkName: "component---src-pages-services-vsl-4-js" */)
}

